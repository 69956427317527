import { hydrate } from "@/utils/form";

export default class Wallet {
    id = null;
    balance = null;

    constructor(object, options) {
        if ("undefined" !== typeof options) {
            if(options.serialize) {
                this.serialize(object);
            }
        }
    }

    serialize (object) {
        hydrate(this, object);
        this.castToCents ();
    }

    castToCents () {
        this.balance = parseInt(this.balance) / 100;
    }
}
