<template>
  <b-card
    :class="innerWidth ? classes : 'ml-0'"
  >
    <balance-wallet-edit
      v-if="mode === 'edit'"
      :wallet="wallet"
      mode="edit"
      @on:interface-switch="onSwitch"
      @reload:wallet="loadClientWallet($route.params.id)"
    />
    <balance-wallet-create
      v-else
      :wallet="wallet"
      mode="create"
      :activated-wallet-form-create="activatedWalletFormCreate"
      @on:interface-switch="onSwitch"
      @reload:wallet="loadClientWallet($route.params.id)"
    />
    <b-row v-if="mode === 'create' && !activatedWalletFormCreate" class="mt-3" align="center">
      <b-col>
        {{ $t('views.client.details.body.right.balance.no-wallet') }}
      </b-col>
    </b-row>
    <b-row
      v-if="!switched"
      no-gutters
      class="mt-3"
      align="center"
    >
      <b-col cols="12">
        <b-col
          cols="12"
          :class="innerWidth ? '' : 'p-0'"
        >
          <d-button
            text="views.client.details.body.right.balance.add-wallet"
            class="btn-text-to-display d-btn-sm btn d-btn-load-document font-text-title"
            icon="fa fa-google-wallet mr-1"
            icon-size="15"
            :class="innerWidth ? '' : ' custom-customer-button mt-4 mb-3'"
            @on:button-click="$router.push({ name: 'customer_wallet-refill_new', params: { id: $route.params.id }})"
          />
        </b-col>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {getClientWallet} from "@api/doinsport/services/client/wallet/wallet.api";
import Wallet from "@/classes/doinsport/Wallet";

export default {
  props: {
    classes: {
      type: String,
      default: 'ml-3'
    }
  },
  data: () => ({
    paymentTokens: [],
    wallet: null,
    activatedWalletFormCreate: false,
    mode: 'create',
    switched: false,
  }),
  components: {
    BalanceWalletEdit: () => import('./BalanceWallet'),
    BalanceWalletCreate: () => import('./BalanceWallet'),
  },
  computed:{
    innerWidth(){
      return this.$store.getters['layout/getInnerWidth'] > 900;
    },
  },
  methods: {
    onSwitch(scope) {
      this.switched = scope;
      if (this.activatedWalletFormCreate) {
        this.activatedWalletFormCreate = false;
      }
    },
    loadClientWallet(clientId) {
      this.wallet = null;

      getClientWallet(clientId)
        .then((response) => {
          if (response.data['hydra:member'].length > 0) {
            this.wallet = new Wallet(response.data['hydra:member'][0], {serialize: true});
            this.mode = 'edit';
          } else {
            this.mode = 'create';
          }
        })
      ;
    }
  },
  created() {
    this.loadClientWallet(this.$route.params.id);
  }
}
</script>
<style lang="scss" scoped>
@import "@lazy/_b-card.scss";
@import "@lazy/_custom-buttom-mobile.scss";

.balance-title {
  text-align: left;
  font: normal normal 900 18px Avenir;
  letter-spacing: 0px;
  color: #707070;
  opacity: 1;
}

</style>
